@import '../../../../scss/theme-bootstrap';

$basic-grid-gutter: 12px;

.basic-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  &__header {
    margin: 0 auto;
  }
  &__item {
    padding-bottom: $basic-grid-gutter;
    .no-padding & {
      padding: 0;
    }
    .basic-grid--small-cols-1 & {
      width: 100%;
    }
    .basic-grid--small-cols-2 & {
      width: 50%;
    }
    .basic-grid--small-cols-3 & {
      width: 33.333%;
    }
    .basic-grid--small-cols-4 & {
      width: 25%;
    }
    @include breakpoint($medium-up) {
      width: 33.333%;
      .basic-grid--medium-cols-1 & {
        width: 100%;
      }
      .basic-grid--medium-cols-2 & {
        width: 50%;
      }
      .basic-grid--medium-cols-3 & {
        width: 33.333%;
      }
      .basic-grid--medium-cols-4 & {
        width: 25%;
      }
    }
  }
}
